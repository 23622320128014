<template>
  <div class="ChangePassword" v-fixed>
    <!-- 头部 -->
    <div class="topbar">
      <div>
        <img @click="$router.push('/UserInfo')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      </div>
      <div class="head-title">{{ $t("修改密碼") }}</div>
    </div>
    <!-- 重置密码 -->
    <div class="content">
      <v-card elevation="0">
        <v-form class="mt-5" ref="form">
          <v-text-field
            readonly
            color="info"
            outlined
            dense
            v-model="userInfo.account"
            prepend-inner-icon="mdi-account"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            color="info"
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請輸入舊密碼')"
            outlined
            dense
            clearable
            v-model="oldPassword"
            :type="'password'"
            :rules="[rules.required, rules.oldPassword]"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            color="info"
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請輸入密碼')"
            outlined
            dense
            clearable
            v-model="password"
            :type="'password'"
            :rules="[rules.required, rules.counter, rules.password]"
            class="rounded-lg"
          ></v-text-field>
          <v-text-field
            color="info"
            dense
            prepend-inner-icon="mdi-lock"
            :placeholder="$t('請再次輸入密碼')"
            outlined
            clearable
            v-model="confirmPassword"
            :type="'password'"
            :rules="[rules.required, rules.confirmPassword]"
            class="rounded-lg"
          ></v-text-field>
          <v-btn
            height="50"
            block
            class="rounded-lg mt-1 btn2"
            bottom
            @click="changePassword"
          >
            {{ $t("確定") }}
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
// 引入i18n
import i18n from "@/i18n/index";
export default {
  data() {
    return {
      // 用户信息
      userInfo: [],
      // 修改密码
      //旧密码
      oldPassword: "",
      password: "",
      confirmPassword: null,
      rules: {
        required: (value) => !!value || i18n.t("必填項"),
        counter: (value) =>
          (value && value.length >= 6 && value.length <= 20) ||
          i18n.t("密碼必須為6-20位英文+數字"),
        password: (value) => {
          const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
          return pattern.test(value) || i18n.t("密碼格式英文+數字6-20位");
        },
        confirmPassword: (value) =>
          value === this.password || i18n.t("兩次密碼不一致"),
      },
    };
  },
  created() {
    var appToken = this.getQueryString("appToken");
    if (appToken) {
      this.$get("/api/account/getNewToken", {
        token: appToken,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
        this.getUserInfo();
      });
    } else {
      this.getUserInfo();
    }
  },
  methods: {
    getQueryString(name) {
      var value;
      var url = window.location.href;
      var pairs = url.split("?");
      if (pairs.length < 2) return value;

      pairs = pairs[pairs.length - 1].split("&");

      if (pairs.length < 1) return value;
      pairs.forEach((element) => {
        var pair = element.split("=");
        var key = pair[0];

        if (key.toLowerCase() == name.toLowerCase()) {
          value = pair[1];
          return;
        }
      });
      return value;
    },
    // 修改密码
    changePassword() {
      if (this.$refs.form.validate()) {
        this.$post("/Api/Account/ChangePassword", {
          oldPassword: this.oldPassword,
          password: this.password,
          confirmPassword: this.confirmPassword,
        }).then((res) => {
          this.$toast(i18n.t("您的密碼已修改，請重新登錄"));
          this.$router.push("/");
          localStorage.removeItem("token");
        });
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$get("/api/Home/UserInfo").then((res) => {
        this.userInfo = res.data;
        // console.log(res.data);
      });
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangePassword {
  padding-top: 46px;
  background-color: #F0F6F9;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    text-align: left;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }

  .content {
    padding: 0 24px;
    .v-card {
      .btn2 {
        background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
        box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
</style>